import { environment } from 'src/environments/environment';
import { origin } from '../shared/utils/http-interceptor';

export abstract class AbstractService {
  private baseUrl: string = environment.allowedDomains[0];
  protected get baseApiUrl(): string {
    /*
      If the app is deployed to a non devops env i.e. sandbox use the origin
      this will allow us to deploy to sandboxes while devops setup in progress
      until we are no longer using sandboxes to verify implementation
    */
    return this.baseUrl.includes(window.location.hostname)
      ? this.baseUrl
      : origin();
  }
}
