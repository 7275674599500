import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { LoginGuardService as LoginGuard } from './auth/login-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'bulk-assign',
    loadChildren: () =>
      import('./bulk-assign/bulk-assign.module').then(
        (m) => m.BulkAssignPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-assigned',
    loadChildren: () =>
      import('./view-assigned/view-assigned.module').then(
        (m) => m.ViewAssignedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'callback',
    loadChildren: () =>
      import('./callback/callback.module').then((m) => m.CallbackPageModule),
  },
  {
    path: 'device-manager',
    loadChildren: () =>
      import('./device-manager/device-manager.module').then(
        (m) => m.DeviceManagerPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'last-point',
    loadChildren: () =>
      import('./last-point/last-point.module').then(
        (m) => m.LastPointPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'current-assets',
    loadChildren: () =>
      import('./current-assets/current-assets.module').then(
        (m) => m.CurrentAssetsPageModule
      ),
  },
  {
    path: 'modify-asset',
    loadChildren: () =>
      import('./modify-asset/modify-asset.module').then(
        (m) => m.ModifyAssetPageModule
      ),
  },
  {
    path: 'historic-asset',
    loadChildren: () =>
      import('./historic-asset/historic-asset.module').then(
        (m) => m.HistoricAssetPageModule
      ),
  },
  {
    path: 'all-assets',
    loadChildren: () =>
      import('./all-assets/all-assets.module').then(
        (m) => m.AllAssetsPageModule
      ),
  },
  {
    path: 'current-devices',
    loadChildren: () =>
      import('./current-devices/current-devices.module').then(
        (m) => m.CurrentDevicesPageModule
      ),
  },
  {
    path: 'tive-history',
    loadChildren: () =>
      import('./tive-history/tive-history.module').then(
        (m) => m.TiveHistoryPageModule
      ),
  },
  {
    path: 'geofence-anchors',
    loadChildren: () =>
      import('./geofence-anchors/geofence-anchors.module').then(
        (m) => m.GeofenceAnchorsPageModule
      ),
  },
  {
    path: 'pairing',
    loadChildren: () =>
      import('./pairing/pairing.module').then((m) => m.PairingModule),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard, LoginGuard]
})
export class AppRoutingModule { }
