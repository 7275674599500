/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AuthConfig } from './auth.config';
import { Router } from '@angular/router';
import auth0 from 'auth0-js';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginState = new BehaviorSubject<boolean>(false);

  errorSubject = new Subject();

  userProfile: any;

  requestedScopes: 'openid profile organization:hulk read:trackerdata write:scoutposition read:scoutposition';
  // Created a WebAuth instance
  auth0 = new auth0.WebAuth({
    // Auth0 Client ID
    clientID: AuthConfig.clientID,
    // Auth0 Account Domain
    domain: AuthConfig.domain,
    // Response type used
    responseType: 'token id_token',
    // The audience used for requesting API access
    audience: AuthConfig.apiUrl,
    // The redirect URI used
    redirectUri: AuthConfig.callbackURL,
    // Scope property is used for both OIDC scopes and API scopes
    scope:
      'openid profile organization:hulk read:trackerdata write:scoutposition read:scoutposition',
  });

  constructor(public router: Router) {}

  getLoginState() {
    return this.loginState.value;
  }

  // Attempts to log in user
  loginUser(loginInfo) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: AuthConfig.realm,
          username: loginInfo.email,
          password: loginInfo.password,
        },
        (err) => {
          reject(err.error_description);
        }
      );
    });
  }

  // Looks for an authentication result and processes it
  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        // window.location.hash = ''
        this.setSession(authResult);

        // TODO: Potentially be making request to bff with returned access token here. It should be attaching the token automatically
        // via the angular-jwt library.

        this.loginState.next(true);

        // TODO: This is where we want to get the users token and make a specified request to the db

        this.router.navigate(['/home']);
      } else if (err) {
        this.router.navigate(['/home']);
        console.log('authentication error', err);
      }
    });
  }

  // Sets the user's Access Token, ID Token, and time when the Access Token will expire
  setSession(authResult) {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    // If there is a value on the scope param from the authResult, use it to set scopes in the session for the user. Otherwise
    // use the scopes as requested. If no scopes were requested, set it to nothing
    const scopes = authResult.scope || this.requestedScopes || '';

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('scopes', JSON.stringify(scopes));
  }

  getUserProfile() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access token must exist to fetch profile.');
    }

    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(
        accessToken,
        (err, profile) => {
          if (profile) {
            this.userProfile = profile;
            resolve(this.userProfile);
          }
        },
        (err) => {
          reject(err.error_description);
        }
      );
    });
  }

  userHasScopes(scopes) {
    const grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
    // return scopes.every(scope => grantedScopes.includes(scope));
    // console.log(grantedScopes)
  }

  // Logs the user out
  logoutUser() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');

    this.loginState.next(false);

    // Go back to the root route (login page)
    this.router.navigate(['/']);
  }

  // Checks whether the expiry time for the Access Token has passed
  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}
