export const bigironSettings = {
  deviceConfig: {
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    label: 'Tracker ID',
    isGtin: false,
    fieldName: 'deviceId',
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to pair',
  },
  unpairConfig: {
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to unpair',
  },
  stepsConfig: {
    trackerLabel: 'Tracker',
    assetMetaLabel: 'Item',
    confirmPairLabel: 'Paired',
    confirmUnpairLabel: 'Unpaired',
  },
  assetConfig: {
    confirmAssetName: false,
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    fields: [
      {
        fieldName: 'itemCoxIoNumber',
        fieldRef: 'Cox IO #',
        createDate: '2023-09-05T06:08:21.153Z',
        charLimit: 50,
        label: 'Cox IO #',
        defaultValue: null,
        displayOrder: 0,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isGtin: true,
        isLargeText: false,
        required: true,
        requiredField: null,
        source: 'bigiron.xlsx',
        updated: false,
        updateDate: '2023-09-05T06:08:21.153Z',
        useField: null,
        isUpdate: true,
      },
    ],
    parseGtin: false,
    requireOneOf: [],
    scanHeader: 'Scan the Item',
    scanSubHeader:
      'Align the barcode (or QR code) on the item in the window below',
    manualHeader: 'Enter the Item Information',
    manualSubHeader:
      'Enter the information for the item you would like to pair',
  },
};

export const brightfarmsSettings = {
  deviceConfig: {
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    label: 'Tracker ID',
    isGtin: false,
    fieldName: 'deviceId',
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to pair',
  },
  unpairConfig: {
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to unpair',
  },
  stepsConfig: {
    trackerLabel: 'Tracker',
    assetMetaLabel: 'Item',
    confirmPairLabel: 'Paired',
    confirmUnpairLabel: 'Unpaired',
  },
  assetConfig: {
    confirmAssetName: false,
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    fields: [
      {
        fieldName: 'itemPurchaseOrder',
        fieldRef: 'Purchase Order',
        createDate: '2023-09-05T06:14:48.539Z',
        charLimit: 50,
        label: 'Purchase Order',
        defaultValue: null,
        displayOrder: 0,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isLargeText: false,
        required: true,
        requiredField: null,
        source: 'brightfarms.xlsx',
        updated: false,
        updateDate: '2023-09-05T06:14:48.539Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemOrigin',
        fieldRef: 'Origin',
        createDate: '2023-09-05T06:14:48.539Z',
        charLimit: 50,
        label: 'Origin',
        defaultValue: null,
        displayOrder: 1,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isLargeText: false,
        required: false,
        requiredField: null,
        source: 'brightfarms.xlsx',
        updated: false,
        updateDate: '2023-09-05T06:14:48.539Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemDestination',
        fieldRef: 'Destination',
        createDate: '2023-09-05T06:14:48.539Z',
        charLimit: 50,
        label: 'Destination',
        defaultValue: null,
        displayOrder: 2,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isLargeText: false,
        required: false,
        requiredField: null,
        source: 'brightfarms.xlsx',
        updated: false,
        updateDate: '2023-09-05T06:14:48.539Z',
        useField: null,
        isUpdate: true,
      },
    ],
    parseGtin: false,
    requireOneOf: [],
    scanHeader: 'Scan the Item',
    scanSubHeader:
      'Align the barcode (or QR code) on the item in the window below',
    manualHeader: 'Enter the Item Information',
    manualSubHeader:
      'Enter the information for the item you would like to pair',
  },
};

export const wellstarSettings = {
  deviceConfig: {
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    label: 'Tracker ID',
    isGtin: false,
    fieldName: 'deviceId',
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to pair',
  },
  unpairConfig: {
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to unpair',
  },
  stepsConfig: {
    trackerLabel: 'Tracker',
    assetMetaLabel: 'Item',
    confirmPairLabel: 'Paired',
    confirmUnpairLabel: 'Unpaired',
  },
  assetConfig: {
    confirmAssetName: true,
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    fields: [
      {
        fieldName: 'itemGtinNumber',
        fieldRef: 'GTIN Number',
        createDate: '2023-09-05T06:21:17.504Z',
        charLimit: 50,
        label: 'GTIN Number',
        defaultValue: null,
        displayOrder: 0,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isLargeText: false,
        required: true,
        requiredField: null,
        source: 'wellstar.xlsx',
        updated: false,
        updateDate: '2023-09-05T06:21:17.504Z',
        useField: null,
        isGtin: true,
        isUpdate: true,
      },
      {
        fieldName: 'itemLotNumber',
        fieldRef: 'Lot Number',
        createDate: '2023-09-06T07:19:07.129Z',
        charLimit: 50,
        label: 'Lot Number',
        defaultValue: null,
        displayOrder: 1,
        hidden: true,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isLargeText: false,
        parsedGtin: true,
        required: false,
        requiredField: null,
        source: 'USER',
        updated: false,
        updateDate: '2023-09-06T07:19:07.129Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemSerialNumber',
        fieldRef: 'Serial Number',
        createDate: '2023-09-06T07:19:07.129Z',
        charLimit: 50,
        label: 'Serial Number',
        defaultValue: null,
        displayOrder: 2,
        hidden: true,
        includeData: true,
        isAssetName: false,
        isDate: false,
        isLargeText: false,
        parsedGtin: true,
        required: false,
        requiredField: null,
        source: 'USER',
        updated: false,
        updateDate: '2023-09-06T07:19:07.129Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemGtinExpirationDate',
        fieldRef: 'Expiration Date',
        createDate: '2023-09-06T07:19:07.129Z',
        charLimit: 50,
        label: 'Expiration Date',
        defaultValue: null,
        displayOrder: 3,
        hidden: true,
        includeData: true,
        isAssetName: false,
        isDate: true,
        isLargeText: false,
        parsedGtin: true,
        required: true,
        requiredField: null,
        source: 'USER',
        updated: false,
        updateDate: '2023-09-06T07:19:07.129Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemAssetName',
        charLimit: 200,
        createDate: '2023-09-06T07:26:10.804Z',
        defaultValue: null,
        description: null,
        displayOrder: 4,
        isAssetName: true,
        isLargeText: false,
        includeData: true,
        label: 'Asset Name',
        required: false,
        requiredField: null,
        source: 'USER',
        updateDate: '2023-09-06T07:26:10.804Z',
        useField: null,
        hidden: true,
      },
    ],
    parseGtin: true,
    requireOneOf: ['itemSerialNumber', 'itemLotNumber'],
    scanHeader: 'Scan the Item',
    scanSubHeader:
      'Align the barcode (or QR code) on the item in the window below',
    manualHeader: 'Enter the Item Information',
    manualSubHeader:
      'Enter the information for the item you would like to pair',
  },
};

export const gkSettings = {
  unpairConfig: {
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to unpair',
  },
  stepsConfig: {
    trackerLabel: 'Tracker',
    assetMetaLabel: 'Item',
    confirmPairLabel: 'Paired',
    confirmUnpairLabel: 'Unpaired',
  },
  assetConfig: {
    confirmAssetName: false,
    defaultEntryMode: 'MANUAL',
    enableEntryMode: false,
    fields: [
      {
        fieldName: 'itemProjectNumber',
        fieldRef: 'Project Number',
        createDate: '2023-05-15T18:17:24.028Z',
        charLimit: 50,
        label: 'Project Number',
        defaultValue: '22003GMOR',
        displayOrder: 0,
        includeData: true,
        isAssetName: false,
        isLargeText: false,
        required: true,
        requiredField: null,
        source: 'v3KM_Expediting Report_All_Locations_May15.csv',
        updated: false,
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemPoNumber',
        fieldRef: 'PO #',
        createDate: '2023-04-07T20:08:48.354Z',
        charLimit: 50,
        label: 'PO #',
        defaultValue: null,
        displayOrder: 1,
        includeData: true,
        isAssetName: false,
        isLargeText: false,
        required: true,
        requiredField: null,
        source: 'v3KM_Expediting Report_All_Locations_May15.csv',
        updated: true,
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemArea',
        fieldRef: 'Area',
        createDate: '2023-04-07T20:08:48.354Z',
        charLimit: 50,
        label: 'Area',
        defaultValue: null,
        displayOrder: 2,
        includeData: true,
        isAssetName: false,
        isLargeText: false,
        required: true,
        requiredField: 'itemPoNumber',
        source: 'v3KM_Expediting Report_All_Locations_May15.csv',
        updated: true,
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemTagNumber',
        fieldRef: 'Tag #',
        createDate: '2023-04-07T20:08:48.354Z',
        charLimit: 50,
        label: 'Tag #',
        defaultValue: null,
        displayOrder: 3,
        includeData: true,
        isAssetName: false,
        isLargeText: false,
        required: false,
        requiredField: 'itemPoNumber',
        source: 'v3KM_Expediting Report_All_Locations_May15.csv',
        updated: true,
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemEquipmentDescription',
        fieldRef: 'Equipment Description',
        createDate: '2023-04-07T20:08:48.354Z',
        charLimit: 1000,
        label: 'Equipment Description',
        defaultValue: null,
        displayOrder: 4,
        includeData: true,
        isAssetName: false,
        isLargeText: true,
        required: false,
        requiredField: 'itemPoNumber',
        source: 'v3KM_Expediting Report_All_Locations_May15.csv',
        updated: true,
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'itemEquipmentType',
        fieldRef: 'Equipment Type',
        createDate: '2023-04-07T20:08:48.354Z',
        charLimit: 50,
        label: 'Equipment Type',
        defaultValue: null,
        displayOrder: 5,
        includeData: true,
        isAssetName: false,
        isLargeText: false,
        required: false,
        requiredField: null,
        source: 'v3KM_Expediting Report_All_Locations_May15.csv',
        updated: true,
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
      },
      {
        fieldName: 'assetDisplayName',
        charLimit: 50,
        createDate: '2023-04-04T19:22:56.512Z',
        defaultValue: null,
        description: null,
        displayOrder: 6,
        isAssetName: true,
        isLargeText: false,
        includeData: true,
        label: 'Asset Display Name',
        required: true,
        requiredMap: {
          itemEquipmentDescription: true,
        },
        source: 'USER',
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: 'itemEquipmentDescription',
        isUpdate: true,
        updated: true,
      },
      {
        fieldName: 'notes',
        charLimit: 250,
        createDate: '2023-04-04T19:28:48.949Z',
        defaultValue: null,
        description: null,
        displayOrder: 7,
        isAssetName: false,
        isLargeText: true,
        includeData: true,
        label: 'Notes',
        required: false,
        requiredMap: {},
        source: 'USER',
        updateDate: '2023-05-15T18:17:24.028Z',
        useField: null,
        isUpdate: true,
        updated: true,
      },
      {
        fieldName: 'itemUseByDate',
        charLimit: 8,
        createDate: '2023-05-25T13:52:30.411Z',
        defaultValue: null,
        description: null,
        displayOrder: 8,
        isAssetName: false,
        isLargeText: false,
        includeData: true,
        label: 'Use By Date',
        required: false,
        requiredField: null,
        source: 'USER',
        updateDate: '2023-06-02T06:49:45.215Z',
        useField: null,
        isUpdate: true,
        isDate: true,
      },
      {
        fieldName: 'itemShipByDate',
        charLimit: 8,
        createDate: '2023-05-25T13:53:56.946Z',
        defaultValue: null,
        description: null,
        displayOrder: 9,
        isAssetName: false,
        isLargeText: false,
        includeData: true,
        label: 'Ship By Date',
        required: false,
        requiredField: null,
        source: 'USER',
        updateDate: '2023-06-02T06:49:46.392Z',
        useField: null,
        isUpdate: true,
        isDate: true,
      },
    ],
    parseGtin: false,
    requireOneOf: [],
    scanHeader: 'Scan the Item',
    scanSubHeader:
      'Align the barcode (or QR code) on the item in the window below',
    manualHeader: 'Enter the Item Information',
    manualSubHeader:
      'Enter the information for the item you would like to pair',
  },
  deviceConfig: {
    defaultEntryMode: 'SCAN',
    enableEntryMode: true,
    label: 'Tracker ID',
    isGtin: false,
    fieldName: 'deviceId',
    scanHeader: 'Scan the Tracker',
    scanSubHeader:
      'Align the barcode (or QR code) on the tracker in the window below',
    manualHeader: 'Enter the Tracker ID',
    manualSubHeader:
      'Type in the ID of the tracker that you would like to pair',
  },
};
