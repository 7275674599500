import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { mockRequest } from './request';
import { environment } from 'src/environments/environment';
import { ResponseInterceptor } from 'src/app/shared/utils/http-interceptor/http.interceptor';

@Injectable()
export class MockInterceptor extends ResponseInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const { production, useLiveData } = environment;
    if (production || useLiveData) {
      return next.handle(request);
    }
    const mockResponse = mockRequest(request);
    if (mockResponse instanceof HttpErrorResponse) {
      return throwError(mockResponse);
    }
    return of(mockRequest(request));
  }
}
