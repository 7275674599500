import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AssetInterface } from '../types/assets.interface';

@Injectable()
export class ViewAssignedService {

  assets$ = new Subject<AssetInterface[]>();
  sampleData: Array<AssetInterface> = [
    {id: '0101', domain: 'mobile', name: 'scout22'},
    {id: '0123', domain: 'mobile', name: 'scout42'},
    {id: '4545', domain: 'big iron', name: 'xbyce2727'},
    {id: '4567', domain: null , name: 'xram4441'}
  ];

  constructor(){

  }
  getSampleData(){
    return this.sampleData;
  };

}
