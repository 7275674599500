import {
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import ListOfOrgs from './data/listoforgs.json';
import UserInfo from './data/userinfo.json';
import ListOfAssets from './data/listofassets.json';
import Settings from './data/settings.json';
import CSVData from './data/csv.json';
import NoCSVData from './data/no-csv-data.json';
import BFCsvData from './data/bfcsv.json';
import Geofences from './data/geofences.json';
import Unpaired from './data/not-paired.json';
import {
  bigironSettings,
  brightfarmsSettings,
  gkSettings,
  wellstarSettings,
} from './values/settings';

export const errorResponse = (
  method: string,
  args: { [key: string]: any } = {}
) =>
  new HttpErrorResponse({
    error: {
      message:
        'Please update test interceptor with matching url and method to for correct endpoint response',
      verb: method,
      ...(args?.error || {}),
    },
    status: args?.status || 404,
  });

export const mockRequest = (
  request: HttpRequest<any>
): HttpResponse<any> | HttpErrorResponse | Error | any => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { url, method, body, params } = request;
  const requestBody = body || {};

  /* Happy path responses */
  if (url.match('/api/getuserinfo') && method === 'GET') {
    return new HttpResponse({
      body: UserInfo,
      status: 200,
    });
  }
  if (url.match('/api/getlistoforgs') && method === 'POST') {
    return new HttpResponse({
      body: ListOfOrgs,
      status: 200,
    });
  }
  if (url.match('/api/getlistofassetsinorg') && method === 'POST') {
    return new HttpResponse({
      body: ListOfAssets,
      status: 200,
    });
  }

  if (url.match('/api/settings')) {
    if (method === 'GET') {
      if (params.get('org') === 'bigiron') {
        return new HttpResponse({
          body: JSON.stringify(bigironSettings),
          status: HttpStatusCode.Ok,
        });
      }

      if (params.get('org') === 'wellstar') {
        return new HttpResponse({
          body: JSON.stringify(wellstarSettings),
          status: HttpStatusCode.Ok,
        });
      }

      if (params.get('org') === 'brightfarms') {
        return new HttpResponse({
          body: JSON.stringify(brightfarmsSettings),
          status: HttpStatusCode.Ok,
        });
      }

      if (params.get('org') === 'gk') {
        return new HttpResponse({
          body: JSON.stringify(gkSettings),
          status: HttpStatusCode.Ok,
        });
      }
    }

    if (method === 'POST') {
      console.log(JSON.parse(body.settings));
      return new HttpResponse({
        body,
        status: HttpStatusCode.Ok,
      });
    }

    return new HttpResponse({
      body: '{}',
      status: HttpStatusCode.Ok,
    });
  }

  if (url.match('/api/csv') && method === 'GET') {
    if (params.get('org') === 'brightfarms') {
      return new HttpResponse({
        body: BFCsvData,
        status: HttpStatusCode.Ok,
      });
    }

    if (params.get('org') === 'gk') {
      return new HttpResponse({
        body: CSVData,
        status: HttpStatusCode.Ok,
      });
    }

    return new HttpResponse({
      body: '',
      status: 204,
    });
  }

  if (url.match('/api/asset') && method === 'DELETE') {
    return new HttpResponse({
      body: {},
      status: 200,
    });
  }

  if (url.match('/api/deboop') && method === 'GET') {
    return new HttpResponse({
      body: Unpaired,
      status: 200,
    });
  }

  if (url.match('/api/deleteaggregatorgeofence') && method === 'POST') {
    const { AggregatorGeofenceList } = Geofences;
    const deletedGeofence = body;
    const updatedGeofenceData = AggregatorGeofenceList.filter(
      (item) =>
        item.AggregatorInternalId != deletedGeofence.AggregatorInternalId
    );
    return new HttpResponse({
      body: {
        deleteResult: 'DELETE_AGGREGATOR_GEOFENCE_SUCCESS',
        AggregatorGeofenceList: updatedGeofenceData,
      },
      status: 200,
    });
  }

  if (url.match('/api/setaggregatorgeofence') && method === 'POST') {
    return new HttpResponse({
      body: Geofences,
      status: 200,
    });
  }
  if (url.match('/api/getaggregatorgeofence') && method === 'GET') {
    return new HttpResponse({
      body: Geofences,
      status: 200,
    });
  }
  if (
    url.match('/api/scoutfixedposition') &&
    ['POST', 'PUT'].includes(method)
  ) {
    return new HttpResponse({
      body: Geofences,
      status: 200,
    });
  }
  return new HttpResponse({ status: 200, body: null });
};
