import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AssetInterface } from './types/assets.interface';

@Injectable()
export class BulkAssignService {
  assets$ = new Subject<AssetInterface[]>();
  sampleData: Array<AssetInterface> = [
    {id:'010101', isChecked: false, isAssigned: true, name: 'scout3456'},
    {id:'ababab', isChecked: false, isAssigned: false, name: 'xkcd43vr'},
    {id:'232323', isChecked: false, isAssigned: false, name: 'scout9876'},
    {id:'yzyzyz', isChecked: false, isAssigned: true, name: '1337c0d34r'}
  ];

  constructor(
    private authService: AuthService,
    ){}

  getSampleData(){
    return this.sampleData;
  }

  addAssignable(id: string, name: string, assigned: boolean): void {
    // const newAsset: AssetInterface = {
    //   id,
    //   isChecked: true,
    //   isAssigned: assigned,
    //   name,
    // };
    // const updatedAssets = [...this.assets$.getValue(), newAsset];
    // this.assets$.next(updatedAssets);
  }

  logoutUser(){
    this.authService.logoutUser();
  }
}
