import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { origin } from '../shared/utils/http-interceptor';
import { AbstractService } from './service';

@Injectable({
  providedIn: 'root',
})
export class DataService extends AbstractService {
  private domainArr: Array<string> = [];

  get domains() {
    return this.domainArr;
  }

  constructor(private httpClient: HttpClient) {
    super();
  }

  getDomainList() {
    return this.httpClient
      .get<Array<string>>(`${this.baseApiUrl}/getDomains`)
      .pipe(
        tap((res) => {
          // I think this is where we need to set the domainArr
          this.domainArr = res;
        })
      );
  }
}
