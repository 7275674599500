import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BulkAssignService } from './bulk-assign/bulk-assign.service';
import { ViewAssignedService } from './view-assigned/view-assigned.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { LoginGuardService } from './auth/login-guard.service';
import { DataService } from './services/database.service';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpInterceptorProviders } from './shared/utils/http-interceptor';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function tokenGetter() {
  return localStorage.getItem('access_token');
}

const originDomain = () => window.location.origin;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [...environment.allowedDomains, originDomain()],
        disallowedRoutes: [],
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuardService,
    AuthService,
    BulkAssignService,
    DataService,
    LoginGuardService,
    ViewAssignedService,
    HttpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
