// QA ENVIRONMENT VARIABLES

export const environment = {
  production: false,
  useLiveData: true,
  allowedDomains: ['https://hulk-mode-qa-lvfoj6rorq-ue.a.run.app/api'],
  // Auth0 QA Tenant
  auth0: {
    clientID: 'n2n3eE2zZIHsb5g0fVwmD5J1igIE03iQ',
    domain: 'littlebirdlabs-qa.us.auth0.com',
    callbackURL: `${window.location.origin}/callback`,
    realm: 'Username-Password-Authentication',
    apiUrl: 'https://qa.littlebirdlabs.com/api',
    scopes: 'openid profile read:trackerdata organization:hulk'
  }
};