import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
// import { DataService } from './shared/services/database.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  isLoggedIn;
  // mockData;

  constructor(private authService: AuthService) {
    authService.handleAuthentication();
    // this.mockData = dataService.getMockValue();
    this.isLoggedIn = this.authService.isAuthenticated();
  }

  ngOnInit() {
  }

  logoutUser(){
    this.authService.logoutUser();
  }
}
