import { environment } from "src/environments/environment";

interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  realm: string;
  apiUrl: string;
}

export const AuthConfig: AuthConfig = environment.auth0;