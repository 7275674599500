// Using an index file to import any http interceptors in order to maintain the correct order of execution.

import { HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseInterceptor } from './http.interceptor';
import { MockInterceptor } from 'src/test/mock/interceptor';

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

const prodProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
];

const devProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true },
];

export const HttpInterceptorProviders = environment.production
  ? prodProviders
  : devProviders;

export const origin = () => {
  const { hostname, protocol, port } = window.location;
  return `${protocol}//${hostname}${port ? ':' + port : ''}/api`;
};
